// Here you can add other styles
.sidebar {
  @media (min-width: 768px) {
    -webkit-box-shadow: 14px 0px 20px -5px rgba(233, 233, 233, 1);
    -moz-box-shadow: 14px 0px 20px -5px rgba(233, 233, 233, 1);
    box-shadow: 14px 0px 20px -5px rgba(233, 233, 233, 1);
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  background: $white;

  .simplebar-content {
    padding: 20px 0 !important;

    li {
      &:last-child {
        padding-bottom: 30px;
        border-bottom: 1px solid #ebeff2;
      }
    }
  }

  .user-container {
    .container-user {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    color: #000000;
    padding: 1rem 1rem 1rem 1.5rem;
    border-bottom: 1px solid #ebeff2;

    @media (max-width: 767px) {
      justify-content: flex-start;
      border-top: 1px solid #ebeff2;
    }

    .image {
      background: #000000;
      color: $white;
      border-radius: 50%;
      margin-right: 10px;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 18px;
        margin-bottom: 0;
        padding: 12px 8px;
      }
    }

    h5 {
      margin-bottom: 0;
      font-size: 16px;
    }

    p {
      color: #bdbdbd;
      font-size: 12px;
      margin-bottom: 0;
    }

    img {
      width: 10px;

      @media (max-width: 767px) {
        margin-left: 20px;
      }
    }

    button {
      margin-top: 20px;
      width: 100%;
    }
  }

  .sidebar-brand {
    background: $white;
    justify-content: flex-start;
    padding: 0 1rem;

    img {
      width: 50px;
    }

    h3 {
      font-weight: 700;
      font-size: 18px;
      color: #3d3d3d;
      margin-left: 10px;
    }
  }

  .sidebar-nav {
    border-top: 1px solid #ebeff2;

    .nav-title,
    .nav-link,
    .nav-icon {
      color: #717171;
    }

    .nav-link {
      padding: 0.8445rem 1rem 0.2rem;
    }

    .nav-icon {
      flex: 0 0 3rem;

      &:first-child {
        margin-left: 0 !important;
      }
    }

    .active,
    .nav-link:hover {
      color: #000000;

      .nav-icon {
        color: #000000;
      }
    }

    .nav-group-toggle {
      &::after {
        filter: invert(43%) sepia(7%) saturate(16%) hue-rotate(340deg) brightness(100%)
          contrast(90%);
      }
    }
  }
}

.wrapper {
  background: #f5f6f8;
}

.header {
  border-color: #e9e9e9;

  .header-dropdown {
    color: #6a707e;

    button {
      background: none;
      padding: 0;
      border: none;
      color: #109cf1;

      &:focus {
        box-shadow: none !important;
        background-color: unset;
        color: #109cf1;
        border-color: unset;
      }
    }
  }

  .header-mob {
    @media (max-width: 767px) {
      button {
        position: absolute;
        right: 0;
      }
    }
  }
}

.header-new {
  @media (max-width: 767px) {
    background: none;
    margin-bottom: 15px;
  }
}

.sidebar-footer {
  background: $white;
  padding: 1rem 2rem;

  @media (max-width: 767px) {
    border-top: 1px solid #ebeff2;
  }

  .main-header {
    display: flex;
    align-items: center;
    color: #717171;

    &:hover {
      cursor: pointer;
    }

    &::after {
      display: inline-block;
      content: url(../assets/images/chevron-down.svg);
      transform: scale(0.6);
    }

    img {
      width: 16px;
      margin-right: 15px;
    }

    p {
      margin-bottom: 0;
      flex: 1;
    }
  }

  ul {
    padding: 0;

    li {
      .nav-link {
        padding: 0.5rem 0;
        color: #717171;
        font-size: 14px;

        &:hover {
          color: #000000;
        }

        border-bottom: 1px solid #ebeff2;

        &::after {
          content: url(../assets/images/chevron-right.svg);
          transform: scale(0.6);
          float: right;
        }
      }

      .active {
        color: #000000;
      }
    }
  }
}

.sidebar-footer-open {
  border-top: none;

  .main-header {
    margin-bottom: 20px;
  }
}

.footer-show {
  flex: unset;
}

.sidebar-close {
  margin-left: -100% !important;
}

.header-sticky {
  @media (max-width: 767px) {
    margin-bottom: 0 !important;
  }
}

.nav-underline {
  border-bottom: 2px solid #ecf0f2;

  .nav-link {
    color: #000000;
  }

  .nav-link.active {
    border-bottom: 3px solid #000000;
  }
}

.tab-content {
  margin-top: 20px;
}

.btn-green {
  background: #44c800 !important;
  border: 1px solid #009df8;
  color: $white !important;

  &:hover,
  &:active,
  &:focus {
    background: #44c800;
    color: $white;
  }
}

.cancel,
.cancel:hover {
  color: #c82000 !important;
}

.actions {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.edit {
  color: #009df8 !important;
}

.card {
  border: none;

  .card-footer {
    background: $white;
    padding: 1rem;
    border-top-color: #ebeff2;
  }
}

.days-list {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  li {
    margin-right: 10px;

    a {
      color: #000000;
      padding: 5px 13px;
      border: 1px solid #009df8;
      border-radius: 50%;
      text-decoration: none;
      display: inline-block;

      &:hover {
        color: #ffffff;
        background: #009df8;
      }
    }

    &:nth-child(1),
    &:nth-child(3) {
      a {
        padding: 5px 10px;
      }
    }

    a.active {
      color: #ffffff;
      background: #009df8;
    }
  }
}

.time_picker_container {
  width: 100px;

  .react_times_button {
    box-shadow: none;
  }

  .active {
    box-shadow: none !important;
  }

  .time_picker_preview {
    height: 45px;
    width: 100px;

    .wrapper {
      padding-left: 0;
      height: auto;
      background: #ffffff;

      .preview_container {
        height: 45px;
        line-height: 45px;
        padding: 0;
        position: relative;
        border: 2px solid #dedede;
        border-radius: 5px;
        padding: 0 10px;

        &::after {
          content: url(../assets/images/chevron-down.svg);
          position: absolute;
          right: 10px;
          top: 2px;
        }
      }
    }
  }

  .modal_container {
    top: 60%;
    right: 0;

    .classic_time {
      padding: 0 15px;
    }
  }
}

.custom-time {
  position: relative;
  border: 2px solid #dedede;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  padding-right: 30px;
  width: auto;
  height: 45px;

  &:focus {
    box-shadow: none;
  }

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  &::after {
    content: url(../assets/images/chevron-down.svg);
    position: absolute;
    right: 10px;
    top: 11px;
  }
}

.c-btn,
.c-btn:hover,
.c-btn:focus {
  background: linear-gradient(87deg, #ff8100, #ff0000);
  color: #fff;
  transition: all ease 0.25s;
  border-color: #ff6d00;
  box-shadow: none !important;
}

.table-head {
  color: #393939 !important;
  font-size: 14px;
  font-weight: 500;
}

.was-validated .form-control:invalid {
  border-color: #e55353;
  background-image: none;
  background-repeat: no-repeat;
}

.was-validated .form-control:valid {
  border-color: #2eb85c;
  background-image: none;
  background-repeat: no-repeat;
}

// .modal .modal-dialog {
//   width: 365px !important;
// }

.invalid {
  font-size: 14px;
  font-weight: bold;
  margin-top: 11px;
  color: #fc5640;
}

.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  display: none;
}

.form-check-input:checked {
  background-color: #009df8;
  border-color: #009df8;
}

.btn-blue {
  background: #009df8 !important;
  border: 1px solid #009df8;
  color: $white !important;

  &:hover,
  &:active,
  &:focus {
    background: #009df8;
    color: $white;
  }
}

.card-header-new {
  border-bottom-color: #ebeff2;
  background: #ffffff;
  padding: 1rem;

  .header-link {
    color: #949494 !important;
    text-decoration: none;
    font-size: 13px;
    display: flex;

    @media (max-width: 767px) {
      span {
        display: none;
      }
    }
  }

  .link-active {
    background: #ff6d00;
    color: #ffffff !important;
    padding: 5px 15px;
    border-radius: 20px;
  }
}

.action-area {
  a {
    color: #009df8;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  a.action-delete {
    color: #c82000;
  }
}

.mw-auto {
  min-width: auto !important;
}

table {
  th {
    font-weight: 500;
    font-size: 15px;
  }

  td {
    font-size: 15px;
  }

  .action-area {
    min-width: 200px;
  }

  tbody {
    tr {
      &:last-child {
        td {
          border-bottom-width: 0;
        }
      }
    }
  }
}

.text-blue {
  color: #009df8;
}

.tab-view {
  .col-md-4 {
    padding-right: 50px;

    @media (max-width: 767px) {
      padding-right: calc(var(--cui-gutter-x) * 0.5);
    }
  }

  .pitch-tab-single {
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 50px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    img {
      width: 100%;
      height: 150px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .content {
      padding: 15px;

      h6 {
        span {
          font-weight: normal;
        }
      }

      a {
        font-size: 13px;
      }

      .seperator {
        color: #717171;
        font-size: 13px;
      }
    }
  }
}

.icon-green {
  color: #ffffff;
  background: #2eb85c;
  border-radius: 50%;
}

.icon-red {
  color: #ffffff;
  background: rgb(200, 32, 0);
  border-radius: 50%;
}

.icon-star {
  color: #ffb946;
}

.custom-input {
  border-radius: 5px;
  border: 2px solid #e1e1e1;
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 400;
  min-height: 44px;
}

.form-label {
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 500;
}

.modal-cancellation {
  textarea {
    max-width: 350px;
  }
}

.custom-radio-black {
  padding: 0 !important;

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    display: inline-block;
    color: #000;
  }

  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #3c3c3c;
    border-radius: 100%;
    background: #fff;
  }

  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: #ff6d00;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.stepper {
  background: #cacaca;
  width: 90%;
  height: 3px;
  position: relative;
  margin: 30px auto 90px auto;

  &:before {
    content: '';
    height: 3px;
    left: 0;
    top: 0;
    position: absolute;
    background: #ff6d00;
  }

  .status {
    position: relative;

    .txt {
      position: absolute;
      font-size: 16px;
      left: -108%;
      color: #3c3c3c;
      font-weight: 400;
      line-height: normal;
      min-width: 100px;
      text-align: center;
      margin-top: 10px;
    }
  }

  .status-round {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ffffff;
    border: 3px solid #bababa;
    color: #ffffff;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      color: #ffffff;
    }

    &:after {
      width: 30px;
      height: 30px;
      position: absolute;
      border-radius: 50%;
      content: '';
      top: 0;
      left: 0;
      display: none;
    }
  }

  .status-round-active {
    color: #ffffff;
    border: none;

    &:after {
      background: transparent;
    }
  }

  &.one-active {
    .summary,
    .payment {
      .status-round {
        background: #ff6d00;
        width: 30px;
        height: 30px;
        border: none;

        &:after {
          display: block;
        }
      }
    }

    &:before {
      width: 0%;
    }
  }

  &.two-active {
    .summary,
    .payment {
      .status-round {
        background: #ff6d00;
        width: 30px;
        height: 30px;
        border: none;

        &:after {
          display: block;
        }
      }
    }

    &:before {
      width: 50%;
    }
  }

  &.three-active {
    .summary,
    .payment,
    .confirm {
      .status-round {
        background: #ff6d00;
        width: 30px;
        height: 30px;
        border: none;

        &:after {
          display: block;
        }
      }
    }

    &:before {
      width: 80%;
    }
  }

  &.complete-step-active {
    .summary,
    .payment,
    .confirm {
      .status-round {
        background: #ff6d00;
        width: 30px;
        height: 30px;
        border: none;

        &:after {
          display: block;
        }
      }
    }

    &:before {
      width: 100%;
    }
  }
}

.custom-hr {
  border: 0;
  border-top: 1px solid #aaaaaa;
}

.mt-c4 {
  margin-top: 34px;
}

.core-hours {
  th,
  td {
    min-width: auto;
  }

  tr {
    vertical-align: middle;

    input {
      min-width: 150px;
      height: 40px;

      &::after {
        top: 8px;
      }
    }
  }
}

.step-image {
  padding: 0;

  li {
    padding: 15px 0;
    border-bottom: 1px solid #ebeff2;
  }
}

.custom-file-step {
  height: 0;
  overflow: hidden;
  width: 0;
}

.custom-file-step + label {
  display: inline-block;
  position: relative;
}

.step {
  border: 2px solid #ebeff2;
  flex: 1;
}

.step-active {
  border: 2px solid #ff6d00;
  flex: 1;
}

ul.pagination {
  // align-items: center;
  .disabled {
    opacity: 50%;
  }

  .page-item {
    .page-link {
      color: #000000;
      border: none;
      border-radius: 50px;
      margin: 0 0.75rem;
      border: 1px solid #009df8;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        background: #009df8;
        color: #ffffff;
        cursor: pointer;
      }
    }

    &:first-child {
      .page-link {
        border: none;
        font-size: 20px;
        padding: 0 10px 0 0;
        font-weight: bold;
        background-color: transparent;

        &:hover {
          background: transparent;
          color: #000000;
        }
      }
    }

    &:last-child {
      .page-link {
        margin: 0;
        border: none;
        font-size: 20px;
        padding: 0 0 0 10px;
        font-weight: bold;
        background-color: transparent;

        &:hover {
          background: transparent;
          color: #000000;
        }
      }
    }
  }

  .active {
    span {
      background-color: #009df8 !important;
      color: #ffffff !important;
    }
  }

  .page-item.active {
    .page-link {
      background-color: #009df8;
      color: #ffffff;
    }
  }

  .page-item.pagination__link--disabled {
    .page-link {
      color: #dddddf;
      background-color: transparent;
    }
  }

  .page-link:focus {
    background-color: transparent;
    box-shadow: none;
  }

  .pagination__link--break {
    background-color: transparent;
    margin-right: 0.75rem;
    padding: 0 0.5rem;
    color: #7c7c7c;
    text-decoration: none;
    font-size: 20px;
  }
}

.loader-wrp {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1056;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
}

.subheading {
  font-weight: 500;
  color: #3c3c3c;
}

.link-under {
  color: #009df8;
  font-weight: 400;
  font-size: 14px;
}

.filter-booking {
  .subheading {
    @media (max-width: 991px) {
      background: #f5f5f5;
      padding: 10px;
      font-size: 14px;
      color: #393939;
      font-weight: 400;
      text-align: center;
      border-radius: 5px;
      text-decoration: underline;
    }
  }

  .filter-desk-mob {
    @media (max-width: 991px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      z-index: 9999;
      padding: 0;
      background: #f5f5f5;
      overflow-y: auto;
    }

    .filter-content {
      @media (max-width: 991px) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .filter-content,
  .modal-exclusion {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;

    input.custom-input,
    select.custom-input,
    .custom-date {
      min-width: 200px;
      max-height: 44px;

      @media (max-width: 991px) {
        min-width: 100%;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        min-width: 100px;
      }
    }

    label {
      font-weight: 500;
      font-size: 14px;
      color: #3c3c3c;
    }

    .btn-danger {
      background: none;
      border: none;
      color: #c82000;
      font-size: 16px;
      text-decoration: underline;
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }
    }

    .custom-date {
      position: relative;

      .icon {
        position: absolute;
        left: 0;
        top: 10px;
        z-index: 1;
      }

      .btn-group {
        width: 100%;

        button.dropdown-toggle {
          padding-left: 40px;
          background: #ffffff;
          border: 2px solid #e1e1e1;
          color: #3c3c3c;
          font-size: 16px;
          font-weight: 400;
          min-height: 44px;
          text-align: left;

          &::after {
            content: unset;
          }

          &:focus {
            box-shadow: none;
          }
        }

        ul {
          padding: 10px;

          li {
            select {
              width: auto;
            }

            .date-head {
              font-size: 14px;
              font-weight: 500;
              color: #3c3c3c;
            }

            .rdrCalendarWrapper {
              border-radius: 5px;
              border: 2px solid #e1e1e1;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}

.custom-date {
  position: relative;

  .icon {
    position: absolute;
    left: 0;
    top: 10px;
    z-index: 1;
  }

  .btn-group {
    width: 100%;

    button.dropdown-toggle {
      padding-left: 40px;
      background: #ffffff;
      border: 2px solid #e1e1e1;
      color: #3c3c3c;
      font-size: 16px;
      font-weight: 400;
      min-height: 44px;
      text-align: left;

      &::after {
        content: unset;
      }

      &:focus {
        box-shadow: none;
      }
    }

    ul {
      padding: 10px;

      li {
        select {
          width: auto;
        }

        .date-head {
          font-size: 14px;
          font-weight: 500;
          color: #3c3c3c;
        }

        .rdrCalendarWrapper {
          border-radius: 5px;
          border: 2px solid #e1e1e1;
          margin-top: 15px;
        }
      }
    }
  }
}

ul.nav {
  @media (max-width: 767px) {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }

  li.nav-item {
    @media (max-width: 767px) {
      white-space: nowrap;
    }
  }
}

.flex-1 {
  flex: 1;
}

.weekly-date {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }

  p {
    margin-bottom: 0;
  }

  .date-sec {
    border: 2px solid #dedede;
    border-radius: 5px;
    padding: 5px 10px;
  }
}

.weekly-table {
  th {
    min-width: auto;
  }

  thead {
    tr {
      text-align: center;

      th {
        background: #edf0f2;

        &:first-child {
          width: 100px;
          background: transparent;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0;
        position: relative;

        &:first-child {
          padding: 0.5rem 0.5rem;
        }
      }

      th {
        &:first-child {
          vertical-align: middle;
          text-align: center;
        }
      }
    }
  }

  .daily-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    li {
      span {
        &:nth-child(4) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .weekly-content {
    ul {
      padding: 0;
      margin: 0;

      li {
        background: #009df8;
        border-radius: 5px;
        color: #ffffff;
        font-weight: 700;
        font-size: 12px;
        padding: 5px;
        margin-bottom: 1px;

        span {
          font-weight: normal;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.filter-close {
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px;
}

.logout {
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  color: #000000;

  &:hover {
    color: #000000;
  }
}

.modal-site {
  input {
    padding-right: 0.75rem !important;
  }
}

.address-section {
  background: rgb(245, 245, 245);
  margin: 10px 35px 20px;
  padding: 15px 20px;

  a {
    color: rgb(0, 157, 248);
  }

  .address {
    position: relative;

    a {
      position: absolute;
      top: 12px;
      right: 10px;
    }

    .icon-grey {
      color: #9a9a9a;
    }
  }
}

.types-table {
  th,
  td {
    min-width: auto;
  }
}

.wrapper-editor {
  border: 2px solid #e1e1e1;
  border-radius: 5px;
}

.toolbar-editor {
  border: none !important;
  border-bottom: 2px solid #e1e1e1 !important;
}

.editor-editor {
  padding: 0 10px;
}

.multiSelectContainer {
  input {
    float: right;
  }

  .searchWrapper {
    input {
      float: unset;
    }
  }

  li {
    border-bottom: 1px solid rgb(235, 239, 242);

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: transparent !important;
      color: #000000 !important;
    }
  }

  .highlightOption {
    background: transparent !important;
    color: #000000 !important;
  }
}

.duration {
  position: relative;

  p {
    position: absolute;
    right: 10px;
    top: 14px;
    font-weight: bold;
    font-size: 12px;
    color: rgb(148, 148, 148);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.dash-overview {
  border: 1px solid #ebeff2;
  border-radius: 5px;
  padding: 20px 0;
  text-align: center;

  p {
    font-size: 16px;
    font-weight: bold;
  }

  div {
    border: 1px solid #ebeff2;
    padding: 5px 10px;
    font-size: 30px;
    font-weight: 300;
    display: inline;
  }
}

.icon-blue {
  color: #009df8;
}

.icon-green-only {
  color: #2ed47a;
}

.icon-red-only {
  color: #c82000;
}

.dashboard {
  .header-dropdown {
    color: #6a707e;
    font-size: 14px;

    button {
      background: none;
      padding: 0;
      border: none;
      color: #109cf1;
      font-size: 14px;

      &:focus {
        box-shadow: none !important;
        background-color: unset;
        color: #109cf1;
        border-color: unset;
      }
    }

    ul {
      li {
        a {
          font-size: 14px;
        }
      }
    }
  }

  div[class^='col-'],
  div[class*=' col-'] {
    display: grid;
  }
}

.dash-chart-dough {
  .chart-wrapper {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.cust-details {
  .img-container {
    border-radius: 5px;
    background: #efefef;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    img {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      margin-right: 15px;
    }

    h6,
    p {
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
    }
  }

  label {
    margin-bottom: 0;
  }
}

.table-customers {
  th,
  td {
    min-width: auto;
  }
}

.react-datepicker__input-container {
  .custom-input {
    width: 100%;
    padding: 0 10px;
  }
}

.custom-inputs {
  position: relative;
  display: block;

  input {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }

  p {
    color: #949494;
  }

  button {
    background: #ff6d00;
    border-color: #ff6d00;
    padding: 0.2rem 0.6rem;
  }

  .choose {
    border-radius: 5px;
    border: 2px solid #e1e1e1;
    padding: 5px 8px;
  }
}

.gen-fixtures {
  background: #f5f5f5;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  border-radius: 5px;
}

.teams-color {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #979797;
}

.text-grey {
  color: #8a8a8a;
}

.fs-14 {
  font-size: 14px;
}

.nav-tabs {
  margin-right: auto;
  margin-left: auto;

  .nav-item {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .nav-link {
    color: #b3b3b3;
    padding: 0;
    border: none;
    padding: 0 15px 10px;
  }

  .nav-link.active {
    font-weight: 600;
    color: #727272;
    border-bottom: 2px solid #000000;
  }
}

.tab-content {
  padding: 0 15px;
}

.pointer {
  cursor: pointer;
}

.color-change {
  display: block;

  button {
    width: 100%;
    border-radius: 5px;
    border: 2px solid #e1e1e1;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span {
    padding: 0 9px;
    border-radius: 50%;
    margin-left: 10px;
    border: 1px solid #979797;
  }

  .dropdown-menu {
    width: 100%;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div[data-reactroot][role='dialog'],
div[data-reactid][role='dialog']:not(.modal) {
  position: relative;
  z-index: 1;
  height: 0;
  overflow: visible;
}

div[data-reactroot][role='dialog'] {
  position: relative;
  z-index: 1;
}

.modal-exclusion,
.confirm-delete-modal {
  background: rgba($color: #000000, $alpha: 0.5);
}

.without-border {
  border: none !important;
  padding: 0 !important;
  min-height: 35px;
  &:focus {
    outline: none;
  }
}
.react-datepicker-popper {
  z-index: 1051 !important;
}

font-weight-normal {
  font-weight: normal !important;
}

.rmdp-container {
  display: block !important;
}

.text-color-black {
  color: #000000 !important;
}

.upload-image {
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
  display: table;
  margin: auto;

  p {
    color: #109cf1;
    margin-top: 10px;
    margin-bottom: 0;
  }

  #upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  label {
    padding: 50px 100px;
  }

  label.image {
    background: rgba(0, 0, 0, 0.5);
  }
}

.without-border {
  border: none !important;
  padding: 0 !important;
  min-height: 35px;

  &:focus {
    outline: none;
  }
}

.background-size-contains {
  background-size: contain !important;
}

.btn-gradient {
  background: linear-gradient(87deg, #ff8100, #ff0000) !important;
  color: #ffffff !important;
  transition: all ease 0.25s;
  border-color: #ff6d00 !important;
}

.btn-gradient:hover {
  border-color: #ff6d00;
  color: #fff;
  transform: scale(1.01);
}

.booking-form-div {
  background: #f0f0f0 !important;
  border-radius: 6px;
}

.multiseslect-bg-white-div {
  background: #fff !important;
}

.search-wrapper {
  .chip {
    background: #ff6d00;
  }
}

.nav-pills {
  .nav-link {
    color: #000 !important;
    cursor: default;
  }
}

.booking-date-nav {
  .nav-item {
    .nav-link {
      &.active {
        background: #fff;
      }
    }
  }
}

.safety-orange-color {
  color: #ff6d00 !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.css-12jo7m5 {
  background: #ff6d00;
  border-radius: 11px !important;
  color: #fff !important;
  display: inline-flex;
  font-size: 13px !important;
  line-height: 19px;
  margin-right: 5px;
}

.css-1rhbuit-multiValue {
  background-color: #ff6d00 !important;
  border-radius: 11px !important;
  color: #fff !important;
}

.icon-gradient {
  color: #ffffff;
  background: #ff6d00;
  border-radius: 50%;
}

.text-gradient {
  color: #ff6d00 !important;
}

.daily-time-list-table-height {
  .table-responsive {
    height: 740px;
  }
}

.daily-table-stricky-header {
  position: sticky;
  top: 0;
  z-index: 20;
}

.h-50px {
  height: 50px;
}

.text-align-end {
  text-align: end !important;
}

.w-180px {
  width: 180px;
}

.transparent-color-checkbox .form-check-label {
  color: transparent !important;
}
